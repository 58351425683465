export const config = {
  bigDataAPI: {
    geocodeClientUrl:
      "https://api.bigdatacloud.net/data/reverse-geocode-client",
  },
  mapBox: {
    baseUrl: "https://api.mapbox.com",
    key: "pk.eyJ1IjoiamV6bWFnaHV5b3AiLCJhIjoiY2x3aDI3ZTYxMGI4eDJxcnkwaGNyY2VoNSJ9.bJygrDhZYQTPdO0JhQEwOw",
    secretKey:
      "sk.eyJ1IjoiamV6bWFnaHV5b3AiLCJhIjoiY2x3aDI0amEwMGQwbTJpcm0zdndmdTU1dCJ9.gxBn77ItXrOKyf2cqktWaQ",
    searchSuggestionSessionToken: "e14ae316-c403-44f5-884d-a39e5af53100",
    retrieveLocationSessionToken: "bcf16ab2-3124-4563-bf33-3e29e810e78d",
  },
};
