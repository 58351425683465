import { HttpErrorResponse, HttpInterceptorFn } from "@angular/common/http";
import { Router } from "@angular/router";
import { Observable, catchError, retry, throwError, timer } from "rxjs";
import { inject } from "@angular/core";
import { HotToastService } from "@ngxpert/hot-toast";

const CONFIG = { count: 3, delay: 1500 };

function backoffRetry<T>({ count, delay } = CONFIG) {
  return (obs$: Observable<T>) =>
    obs$.pipe(
      retry({
        count,
        delay: (_, retryIndex) => {
          const d = Math.pow(2, retryIndex - 1) * delay;
          return timer(d);
        },
      }),
    );
}

export const httpErrorInterceptorService: HttpInterceptorFn = (req, next) => {
  const toastService = inject(HotToastService);
  const route: Router = inject(Router);

  return next(req).pipe(
    catchError((err: any) => {
      // Remove caught: HttpResponse
      if (err instanceof HttpErrorResponse) {
        // Handle HTTP errors
        if (err.status === 401) {
          toastService.warning(
            "Unauthorized request. Please log in to access this resource.",
          );

          route.navigate(["/auth/login"]);
        }

        if (err.status === 403) {
          return throwError(
            () =>
              new Error(
                "Forbidden request. You do not have permission to access this resource.",
              ),
          );
        }

        if (err.status === 404) {
          return throwError(
            () => new Error("Resource not found. Please try again later."),
          );
        }

        // Handle 500 Internal Server Error errors with retry
        if (err.status >= 500) {
          return next(req).pipe(
            backoffRetry(),
            catchError(() => {
              return throwError(
                () =>
                  new Error("Something went wrong. Please try again later."),
              );
            }),
          );
        }
      }

      // Re-throw the error to propagate it further
      return throwError(() => err);
    }),
  );
};
