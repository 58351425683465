import { HttpInterceptorFn } from "@angular/common/http";

export const httpFormDataInterceptorService: HttpInterceptorFn = (req, next) => {
  // Check if the request body is a FormData instance.
  if (req.body instanceof FormData) {
    // Create a new FormData instance to ensure immutability.
    const formData = req.body;

    // Use the URL API to parse the URL (using window.location.origin as a base).
    try {
      const parsedUrl = new URL(req.url, window.location.origin);

      // Iterate over each query parameter and add it to the FormData.
      parsedUrl.searchParams.forEach((value, key) => {
        // Only add if it doesn't already exist (optional logic).
        if (!formData.has(key)) {
          formData.append(key, value);
        }
      });

      // Optionally, remove the query parameters from the URL.
      const cleanUrl = `${parsedUrl.origin}${parsedUrl.pathname}`;
      // Clone the request with the new URL and updated FormData.

      req = req.clone({
        url: cleanUrl,
        body: formData,
      });
    } catch (error) {
      console.error("Error parsing URL in Form Data Interceptor", error);
    }
  }

  return next(req);
};
