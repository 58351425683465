import { HttpInterceptorFn } from "@angular/common/http";
import { inject } from "@angular/core";
import { finalize } from "rxjs";
import { LoaderService } from "@core/services";

export const httpLoaderInterceptorService: HttpInterceptorFn = (req, next) => {
  const loaderService = inject(LoaderService);

  loaderService.startLoading();

  return next(req).pipe(    
    finalize(() => {
      loaderService.stopLoading();
    }),
  );
};