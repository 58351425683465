import { Route } from "@angular/router";

export const routes: Route[] = [
  {
    path: "",
    loadChildren: () => import("./@core/auth/auth.routes").then((m) => m.routes),
  },
  {
    path: "dashboard",
    loadChildren: () => import("./dashboard/dashboard.routes").then((r) => r.dashboardRoutes),
  },
];
