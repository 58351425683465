import { HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { AuthService } from '@core/services';
import { untilDestroyed } from '@libs/utils';
import { switchMap } from 'rxjs';

export const httpTokenInterceptorService: HttpInterceptorFn = (req, next) => {
  const authService = inject(AuthService);
  const takeUntilDestroyed = untilDestroyed();

  return authService.idToken$.pipe(
    takeUntilDestroyed(),
    switchMap((token) => {
      // Check if the token is null or empty and handle accordingly
      if (!token) {
        // If no token, forward the original request
        return next(req);
      }

      // Clone the request and add the authorization header with the token
      const authReq = req.clone({
        headers: req.headers.set('Authorization', `Bearer ${token}`),
      });

      // Pass on the modified request
      return next(authReq);
    })
  );
};
