import {
  ApplicationConfig,
  ErrorHandler,
  provideZoneChangeDetection,
} from "@angular/core";
import { provideRouter } from "@angular/router";
import { provideAnimationsAsync } from "@angular/platform-browser/animations/async";
import { provideHttpClient, withInterceptors } from "@angular/common/http";
import { routes } from "./app.routes";
import { provideHotToastConfig } from "@ngxpert/hot-toast";
import {
  GlobalErrorHandler,
  httpErrorInterceptorService,
  httpFormDataInterceptorService,
  httpLoaderInterceptorService,
  httpTokenInterceptorService,
} from "@core/interceptors";
import { initializeApp, provideFirebaseApp } from "@angular/fire/app";
import { getAuth, provideAuth } from "@angular/fire/auth";
import { environment } from "@env/environment";
import { API_V1_BASE_URL } from "@core/http";

export const appConfig: ApplicationConfig = {
  providers: [
    { provide: API_V1_BASE_URL, useValue: environment.api.baseUrl },
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideRouter(routes),
    provideAnimationsAsync("animations"),
    provideHotToastConfig({
      position: "top-right",
      duration: 5000,
    }),
    provideHttpClient(
      withInterceptors([
        // Order matters here as the intercept function will be executed in the order they are provided
        httpFormDataInterceptorService,
        httpTokenInterceptorService,
        httpLoaderInterceptorService,
        httpErrorInterceptorService,
      ]),
    ),
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideAuth(() => getAuth()),
  ],
};
